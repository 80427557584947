<div class="relative">
  <input type="text" readonly id="daterange-picker" name="daterange-picker" [placeholder]="placeholder"
    [(ngModel)]="dateTimeString" (click)="onToggleDatePicker()">

  <div class="absolute z-10 mt-1 w-max bg-white min-w-[400px]" *ngIf="showPicker" [ngClass]="{'min-w-[500px]': enableTime }">
    <!-- Calendar -->
    <div class="border-2 border-neutral-200 text-neutral-700">
      <div class="flex">
         <!-- List of predefined days -->
        <div class="flex flex-col py-2 text-sm border-r-2 w-max border-neutral-200">
          @for (day of dayOptions; track $index) {
          <span class="px-4 py-2 hover:cursor-pointer hover:bg-rose-600 hover:text-white"
            [ngClass]="{'bg-rose-600 text-white': day.selected}" (click)="onDateSelected(day.date)">
            {{ day.label | titlecase }}
          </span>
          }
        </div>

        @if (enableTime) {
          <div class="flex flex-col py-2 overflow-y-auto text-sm border-r-2 w-max border-neutral-200 max-h-80 time-selectors">
            @for (time of timeOptions; track $index) {
              <span class="px-4 py-2 hover:cursor-pointer hover:bg-rose-600 hover:text-white" [ngClass]="{'bg-rose-600 text-white': time.selected}" (click)="onTimeSelected(time.value)">
                {{ time.label }}
              </span>
              }
          </div>
        }

        <div class="relative flex-1 px-5 py-4">
          <div id="calendar" class="flex justify-between gap-6 calendar">
            <!-- Show previous month -->
            <div class="absolute left-4">
              <i class="fa-solid fa-chevron-left hover:cursor-pointer" (click)="onPreviousMonth()"></i>
            </div>

            <!-- First month -->
            <div class="flex-1 month first">
              <ng-container [ngTemplateOutlet]="monthTemplate"
                [ngTemplateOutletContext]="{ month: month, days: dayOfMonth, monthIndex: 1 }"></ng-container>
            </div>

            <!-- Show next month -->
            <div class="absolute right-4">
              <i class="fa-solid fa-chevron-right hover:cursor-pointer" (click)="onNextMonth()"></i>
            </div>
          </div>

          @if (enableTime) {
            <hr class="my-3">
            <div class="flex justify-center">
              <input type="text" placeholder="HH" class="mr-1 text-center time-input" maxlength="2" (keydown)="digitOnly($event)" [(ngModel)]="hour" (blur)="onHourChange()">
              <input type="text" placeholder="mm" class="mr-1 text-center time-input" maxlength="2" (keydown)="digitOnly($event)" [(ngModel)]="minute" (blur)="onMinuteChange()">
              <input type="text" placeholder="ss" class="text-center time-input" maxlength="2" (keydown)="digitOnly($event)" [(ngModel)]="second" (blur)="onSecondChange()">
            </div>
          }

        </div>
      </div>

      <div class="flex items-center justify-end gap-2 p-4 border-t-2 border-neutral-200">
        <button class="button is-small" (click)="onCancel()">Cancel</button>
        @if (enableTime) {
          <button class="button is-primary apply-btn" (click)="onApplyDateTime()" [disabled]="isDisableApply">Apply</button>
        }
      </div>
    </div>

    <!-- Month/Year Selections -->
    <div [ngClass]="{'scale-100': showMonthYearSelection }"
      class="absolute top-0 w-full h-full p-4 transition-transform duration-150 origin-center scale-0 bg-white border-2 border-neutral-200 text-neutral-700">
      <div class="flex flex-col justify-between h-full gap-4">
        <div>
          <div class="mb-2 text-center">Years</div>
          <div class="grid grid-cols-6 gap-2">
            @for (year of years; track $index) {
            <div
              class="flex-1 py-2 text-center border-2 rounded-md hover:cursor-pointer hover:border-pink-500 hover:text-pink-500"
              [ngClass]="{'border-pink-500 text-pink-500': selectedYear == year, 'border-neutral-200': selectedYear != year }"
              (click)="onYearSelected(year)">
              {{ year }}
            </div>
            }
          </div>
        </div>

        <div>
          <div class="mb-2 text-center">Months</div>
          <div class="grid grid-cols-6 gap-2">
            @for (month of months; track $index) {
              <div
                class="flex-1 py-2 text-center border-2 rounded-md border-neutral-200 hover:cursor-pointer hover:border-pink-500 hover:text-pink-500"
                [ngClass]="{'border-pink-500 text-pink-500': selectedMonth == month.value, 'border-neutral-200': selectedMonth != month.value }"
                (click)="onMonthSelected(month.value)">
                {{ month.label }}
              </div>
            }
          </div>
        </div>

        <div class="text-center">
          <button (click)="onToggleMonthYear(0)" class="button-outline">Close</button>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #monthTemplate let-month="month" let-days="days" let-monthIndex="monthIndex">
  <div class="mb-3 text-center text-md">
    <span class="hover:cursor-pointer hover:text-pink-500 hover:underline" (click)="onToggleMonthYear(monthIndex)">
      {{ month.toFormat('LLLL yyyy') }}
    </span>
  </div>
  <div class="grid grid-cols-7 grid-rows-5 text-sm gap-y-1">
    @for (day of daysOfWeek; track $index) {
      <span class="font-semibold">{{ day }}</span>
    }

    @for (day of days; track $index) {
      <ng-container [ngTemplateOutlet]="dayTemplate"
        [ngTemplateOutletContext]="{day: day, month: month }"></ng-container>
    }
  </div>
</ng-template>

<ng-template #dayTemplate let-day="day" let-month="month">
  <span *ngIf="!day" class="p-1 w-7">&nbsp;</span>
  <span *ngIf="day" class="pickable" (click)="select(day)" [ngClass]="[day.month == month.month?'in':'out']"
    [class.start]="day.toFormat('yyyy-LL-dd') == date?.toFormat('yyyy-LL-dd')" [class.end]="day.toFormat('yyyy-LL-dd') == date?.toFormat('yyyy-LL-dd')">{{
    day.toFormat('dd') }}</span>
</ng-template>