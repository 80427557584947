import _ from 'lodash-es';
import { DateTime } from 'luxon';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';


type DayOption = {
  label: string,
  selected: boolean,
  date: DateTime
}

type TimeOption = {
  label: string,
  selected: boolean,
  value: string
}

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule
  ],
})
export default class DatePickerComponent implements OnInit, OnDestroy {
  @Output() dateTimePicked = new EventEmitter<DateTime>();

  @Input() initDateTime!: DateTime;
  @Input() enableTime!: boolean;
  @Input() predefinedDays = ['today', 'yesterday', 'this-week', 'last-week', 'this-month', 'last-month'];

  showPicker = false;
  date!: DateTime | null;
  dateTimeString!: string;
  placeholder!: string;

  dayOptions: DayOption[] = [];
  timeOptions: TimeOption[] = [];

  timezone: string = DateTime.local().zoneName ?? 'utc';

  daysOfWeek: (string)[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  dayOfMonth: (DateTime | null)[] = [];
  month!: DateTime;

  timeStep = 30; // minutes
  times: (string)[] = [];
  hour: string | null = null;
  minute: string | null = null;
  second: string | null = null;

  showMonthYearSelection = false;
  selectedYear!: number;
  selectedMonth!: number;
  monthIndex!: number;
  years: (number)[] = [];
  months = [
    { label: 'Jan', value: 1 }, { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 }, { label: 'Apr', value: 4 },
    { label: 'May', value: 5 }, { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 }, { label: 'Aug', value: 8 },
    { label: 'Sep', value: 9 }, { label: 'Oct', value: 10 },
    { label: 'Nov', value: 11 }, { label: 'Dec', value: 12 }
  ];

  constructor() { }

  ngOnInit() {
    const now = DateTime.now();
    this.render(now);
    this.setupDays(now);
    this.setupTimes();
    this.setupInit();

    const currentYear = now.year;
    this.years = _.range(-1, 5).map(v => currentYear + v);
    this.placeholder = this.enableTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
  }

  ngOnDestroy(): void {
  }

  get isDisableApply() {
    return !this.date || (this.enableTime && !(this.hour && this.minute && this.second));
  }

  render(today: DateTime) {
    const month = today?.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.dayOfMonth = this.setupDayOfMonth(month, 1);
    this.month = month;
  }

  setupDays(today: DateTime) {
    for (const day of this.predefinedDays) {
      const date = this.determineDay(today, day);
      if (!date) { continue; }
      this.dayOptions.push({
        label: day.replace('-', ' '),
        date,
        selected: false
      });
    }
  }

  setupTimes() {
    const start = DateTime.fromObject({ hour: 0, minute: 0, second: 0 });
    const end = DateTime.fromObject({ hour: 23, minute: 59, second: 0 });
    let time = start;
    while (time <= end) {
      this.timeOptions.push({
        label: time.toFormat('HH:mm'),
        value: time.toFormat('HH:mm:ss'),
        selected: false
      });
      time = time.plus({ minutes: this.timeStep });
    }
  }

  setupInit() {
    if (!this.initDateTime) { return; }
    this.date = this.initDateTime;
    this.dateTimeString = this.initDateTime.toFormat('yyyy-LL-dd');
    this.onDateSelected(this.initDateTime);

    if (this.enableTime) {
      this.onTimeSelected(this.initDateTime.toFormat('HH:mm:ss'));
      this.dateTimeString = this.initDateTime.toFormat('yyyy-LL-dd HH:mm:ss');
    }
  }

  determineDay(start: DateTime, day: string) {
    let date;
    switch (day) {
      case 'today':
        date = start;
        break;
      case 'yesterday':
        date = start.minus({ day: 1 });
        break;
      case 'tomorrow':
        date = start.plus({ day: 1 });
        break;
      case 'in-2-day':
        date = start.plus({ day: 2 });
        break;
      case 'in-3-day':
        date = start.plus({ day: 3 });
        break;
      case 'this-week':
        date = start.startOf('week');
        break;
      case 'last-week':
        date = start.minus({ week: 1 }).startOf('week');
        break;
      case 'next-week':
        date = start.plus({ week: 1 }).startOf('week');
        break;
      case 'this-month':
        date = start.startOf('month');
        break;
      case 'last-month':
        date = start.minus({ month: 1 }).startOf('month');
        break;
      case 'next-month':
        date = start.plus({ month: 1 }).startOf('month');
        break;
      default:
        console.log('determineDay.unknown-day', { day });
    }

    if (date) { date = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) };
    if (this.enableTime) (this.onTimeSelected("00:00:00"))
    return date;
  }

  setupDayOfMonth(month: DateTime, index: number): (DateTime | null)[] {
    const start = month.minus({ days: month.weekday - 1 });
    const hide: number = month.month + ((index === 1) ? 1 : -1);
    const arr: DateTime[] = Array.from({ length: 42 }, (v, i) => start.plus({ days: i }));
    let dayOfMonth: (DateTime | null)[] = arr.map(v => v.month === hide ? null : v);
    while (dayOfMonth.slice(-7).every(v => v === null || v.month !== month.month)) {
      dayOfMonth = dayOfMonth.slice(0, -7);
    }
    return dayOfMonth;
  }

  onHourChange() {
    let hour = !this.hour ? 0 : parseInt(this.hour);
    hour = hour < 0 ? 0 : hour > 23 ? 23 : hour;
    this.hour = _.padStart(hour.toString(), 2, '0');
    this.checkIsTimeOptionSelected();
  }

  onMinuteChange() {
    let minute = !this.minute ? 0 : parseInt(this.minute);
    minute = minute < 0 ? 0 : minute > 59 ? 59 : minute;
    this.minute = _.padStart(minute.toString(), 2, '0');
    this.checkIsTimeOptionSelected();
  }

  onSecondChange() {
    let second = !this.second ? 0 : parseInt(this.second);
    second = second < 0 ? 0 : second > 59 ? 59 : second;
    this.second = _.padStart(second.toString(), 2, '0');
    this.checkIsTimeOptionSelected();
  }

  digitOnly($event: KeyboardEvent) {
    const charCode = $event.key.charCodeAt(0);
    if (charCode != 84 && charCode != 66 && (charCode < 48 || charCode > 57)) {
      $event.preventDefault();
    }
  }

  checkIsTimeOptionSelected() {
    this.timeOptions.forEach(r => r.selected = false);
    const hour = this.hour ? parseInt(this.hour) : 0;
    const minute = this.minute ? parseInt(this.minute) : 0;
    const second = this.second ? parseInt(this.second) : 0;
    const value = DateTime.fromObject({ hour, minute, second }).toFormat('HH:mm:ss');
    const opt = this.timeOptions.find(r => r.value === value);
    if (opt) {
      opt.selected = true;
    }
  }

  onToggleMonthYear(monthIndex: number) {
    let month = null;
    if (monthIndex && monthIndex === 1) {
      month = this.month;
    }

    this.selectedYear = month ? month.year : 0;
    this.selectedMonth = 0;
    this.monthIndex = monthIndex;
    this.showMonthYearSelection = !this.showMonthYearSelection;
  }

  onYearSelected(year: number) {
    if (this.selectedYear === year) {
      this.selectedYear = 0;
    } else {
      this.selectedYear = year;
    }
    if (this.selectedYear && this.selectedMonth) { this.onMonthYearSelected(); }
  }

  onMonthSelected(month: number) {
    if (this.selectedMonth === month) {
      this.selectedMonth = 0;
    } else {
      this.selectedMonth = month;
    }
    if (this.selectedYear && this.selectedMonth) { this.onMonthYearSelected(); }
  }

  onMonthYearSelected() {
    const index = this.monthIndex;
    const month = this.selectedMonth;
    const year = this.selectedYear;

    let first = DateTime.now();
    switch (index) {
      case 1: first = first.set({ month, year }); break;
      case 2: first = first.set({ month, year }).plus({ month: -1 }); break;
    }

    this.render(first);
    this.onToggleMonthYear(0);
  }

  onPreviousMonth() {
    const first = this.month.plus({ month: -1 });
    this.render(first);
  }

  onNextMonth() {
    const first = this.month.plus({ month: 1 });
    this.render(first);
  }

  onDateSelected(date: DateTime) {
    this.date = date;

    this.dayOptions.forEach(r => r.selected = false);
    const range = this.dayOptions.find(r => r.date.toFormat('yyyy-LL-dd') === date.toFormat('yyyy-LL-dd'));
    if (range) {
      range.selected = true;
    }

    if (!this.enableTime) {
      this.dateTimePicked.emit(date);
      this.showPicker = false;
      this.dateTimeString = date.toFormat('yyyy-LL-dd');
    }
  }

  onTimeSelected(time: string) {
    const [hour, minute, second] = time.split(':');
    this.hour = hour;
    this.minute = minute;
    this.second = second;

    const value = DateTime.fromObject({ hour: parseInt(this.hour), minute: parseInt(this.minute), second: parseInt(this.second) }).toFormat('HH:mm:ss');
    this.timeOptions.forEach(r => r.selected = false);
    const opt = this.timeOptions.find(r => r.value === value);
    if (opt) {
      opt.selected = true;
    }
  }

  onToggleDatePicker() {
    if (!this.showPicker) {
      this.showPicker = true;
    } else {
      this.onCancel();
    }
  }

  onCancel() {
    this.showPicker = false;
  }

  onReset() {
    this.date = null;
    this.render(DateTime.now());
  }

  onApplyDateTime() {
    if (!this.date) { return; }

    if (this.hour === null) { this.hour = '00'; }
    if (this.minute === null) { this.minute = '00'; }
    if (this.second === null) { this.second = '00'; }

    this.date = this.date.set({ hour: parseInt(this.hour), minute: parseInt(this.minute), second: parseInt(this.second) });
    this.dateTimePicked.emit(this.date);
    this.showPicker = false;
    this.dateTimeString = this.date.toFormat('yyyy-LL-dd HH:mm:ss');
  }

  select(day: DateTime) {
    this.onDateSelected(day);
  }

  onValueChange(dateTime: DateTime) {
    if (!dateTime) { return }
    this.dateTimePicked.emit(dateTime);
    if (this.enableTime) {
      this.dateTimeString = dateTime.toFormat('yyyy-LL-dd HH:mm:ss');
    } else {
      this.dateTimeString = dateTime.toFormat('yyyy-LL-dd');
    }

  }

}

export { DatePickerComponent };